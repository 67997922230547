import React, { useEffect } from "react";
import "./App.css";
import Hero from "./components/Hero";
import WhyChooseUs from "./components/WhyChooseUs";

import AOS from "aos";
import "aos/dist/aos.css";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Team from "./components/Team";
import JoinUsSection from "./components/JoinUsSection";
import ProjectsShowcase from "./components/ProjectShowcase";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <Hero />
      <WhyChooseUs />
      <AboutUs />
      <JoinUsSection />
      <ProjectsShowcase />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
