import React, { useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import nwp1 from "../assets/images/nwp1.jpeg";
import nwp2 from "../assets/images/nwp2.jpeg";
import tcai1 from "../assets/images/tcai1.jpg";
import tcai2 from "../assets/images/tcai2.jpg";
import tcai3 from "../assets/images/tcai3.jpg";
import tcai4 from "../assets/images/tcai4.jpeg";
import tcai5 from "../assets/images/tcai5.jpeg";
import tosd1 from "../assets/images/tosd1.jpg";
import tosd2 from "../assets/images/tosd2.jpg";
import tosd3 from "../assets/images/tosd3.jpg";

interface ProjectImage {
  url: string;
  alt: string;
}

interface Project {
  id: number;
  title: string;
  description: string;
  images: ProjectImage[];
  tags?: string[];
}

const ProjectsShowcase = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [currentImageIndices, setCurrentImageIndices] = useState<{
    [key: number]: number;
  }>({});

  const projects: Project[] = [
    {
      id: 1,
      title: "Nature Walk - C-Cube",
      description:
        "Interactive nature walk application for nature enthusiasts, featuring guided tours and rich media.",
      images: [
        { url: nwp1, alt: "Nature Walk Project Image 1" },
        { url: nwp2, alt: "Nature Walk Project Image 2" },
      ],
      tags: ["Nature", "Interactive", "Guided Tours"],
    },
    {
      id: 2,
      title: "The Climate Ambassadors' Initiative",
      description:
        "A platform aimed at empowering individuals to take climate action through community projects and awareness campaigns.",
      images: [
        { url: tcai1, alt: "Climate Ambassadors Initiative Image 1" },
        { url: tcai2, alt: "Climate Ambassadors Initiative Image 2" },
        { url: tcai3, alt: "Climate Ambassadors Initiative Image 3" },
        { url: tcai4, alt: "Climate Ambassadors Initiative Image 4" },
        { url: tcai5, alt: "Climate Ambassadors Initiative Image 5" },
      ],
      tags: ["Climate Action", "Community", "Sustainability"],
    },
    {
      id: 3,
      title: "The Oceanic Synergy Dialogue",
      description:
        "A podcast series in collaboration with the Norwegian Embassy, focusing on marine conservation and international cooperation.",
      images: [
        { url: tosd1, alt: "Oceanic Synergy Dialogue Image 1" },
        { url: tosd2, alt: "Oceanic Synergy Dialogue Image 2" },
        { url: tosd3, alt: "Oceanic Synergy Dialogue Image 3" },
      ],
      tags: ["Marine Conservation", "Podcast", "International Cooperation"],
    },
  ];

  const nextProject = () => {
    setCurrentProjectIndex((prev) =>
      prev === projects.length - 1 ? 0 : prev + 1,
    );
  };

  const prevProject = () => {
    setCurrentProjectIndex((prev) =>
      prev === 0 ? projects.length - 1 : prev - 1,
    );
  };

  const nextImage = (projectId: number) => {
    setCurrentImageIndices((prev) => ({
      ...prev,
      [projectId]:
        (prev[projectId] === undefined ? 0 : prev[projectId]) ===
        projects.find((p) => p.id === projectId)!.images.length - 1
          ? 0
          : (prev[projectId] || 0) + 1,
    }));
  };

  const prevImage = (projectId: number) => {
    setCurrentImageIndices((prev) => ({
      ...prev,
      [projectId]:
        (prev[projectId] === undefined ? 0 : prev[projectId]) === 0
          ? projects.find((p) => p.id === projectId)!.images.length - 1
          : (prev[projectId] || 0) - 1,
    }));
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8 md:py-16">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Projects</h1>
      </div>

      {/* Projects Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white rounded-lg shadow-xl overflow-hidden"
          >
            {/* Image carousel using Swiper */}
            <div className="relative max-h-[400px]">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                navigation
                modules={[Navigation, Pagination]}
                autoplay
                pagination={{ clickable: true }}
                className="w-full h-full"
              >
                {project.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image.url}
                      alt={image.alt}
                      className="w-full h-full object-cover max-h-[300px]"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {/* Project info */}
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
              <p className="text-gray-600 mb-4">{project.description}</p>

              {/* Tags */}
              {project.tags && (
                <div className="flex flex-wrap gap-2">
                  {project.tags.map((tag) => (
                    <span
                      key={tag}
                      className="px-2 py-1 bg-gray-100 text-gray-700 text-sm rounded-full"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsShowcase;
